import React from 'react'
import { useEffect, useState } from 'react'
import { Input, Select, Modal, Form, InputNumber } from 'antd'
import { observer } from 'mobx-react-lite'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { validateEmail, validatePassword, validatePhone } from '../../helpers/functions'
import { debounce, trim } from 'lodash'
import { BANKS, ROLE, ROLES } from '../../helpers/constants'

import { useStore } from '../../hooks'
import { rule } from 'src/helpers/validateRule'
import useIsMobile from 'src/hook/hookDevice'
import Button from 'antd/es/button'
const { Option } = Select

const formItemLayout = {
   labelCol: {
      xs: { span: 24 },
      sm: { span: 8 }
   },
   wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
   }
}

const CreateEmployeeScreen = observer((props: any) => {
   const { t } = useTranslation()

   const { visible, onOk, onCancel, userSelected } = props
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [isLoading, setIsLoading] = useState(false)
   const CheckinStore = useStore('CheckinStore')
   const [form] = Form.useForm()

   const isMobile = useIsMobile()

   useEffect(() => {
      // getDataEmployee()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const onFinish = async (values: any) => {
      // console.log('Received values of form: ', values)
      const result = await CheckinStore.createTicket({
         name: values?.name || '',
         phoneNumber: values?.phoneNumber || '',
         // licensePlate: values?.licensePlate || '',
         // dateRegister: values?.dateRegister || ''
      })
      if (result) {
         form.resetFields()
         onOk()
      }
   }

   const onChangeDate = (date: any) => {
      console.log(date)
   }

   const prefixSelector = (
      <Form.Item name="prefix" noStyle>
         <Select disabled>
            <Option value="84">+84</Option>
         </Select>
      </Form.Item>
   )
   return (
      <Modal
         width={isMobile ? '100%' : '68%'}
         visible={visible}
         centered
         title={'Tạo vé xem ca nhạc'}
         okText={'Tạo'}
         cancelText={t(GLOBAL_CLIENT.huy)}
         onCancel={onCancel}
         onOk={form.submit}
      >
         <Form
            {...formItemLayout}
            form={form}
            name="Tạo vé"
            onFinish={debounce(onFinish, 400)}
            initialValues={{
               prefix: '+84'
            }}
            scrollToFirstError
         >
            <Form.Item name="name" initialValue={userSelected?.name} label={t(GLOBAL_CLIENT.fullname)}>
               <Input maxLength={30} disabled />
            </Form.Item>
            <Form.Item name="phoneNumber" initialValue={userSelected?.phoneNumber} label={t(GLOBAL_CLIENT.phone)}>
               <Input
                  addonBefore={prefixSelector}
                  placeholder={t(GLOBAL_CLIENT.phoneEx)}
                  style={{ width: '100%' }}
                  disabled
               />
            </Form.Item>

            <Form.Item name="licensePlate" initialValue={userSelected?.licensePlate} label={'Biển kiểm soát'}>
               <Input placeholder={t(GLOBAL_CLIENT.enterPassword)} disabled />
            </Form.Item>

            {/* <Form.Item label="Ngày xem" rules={[{ required: true }]} name="dateRegister" className="gx-mb-2">
               <Select
                  className="m-full-width"
                  defaultValue="14/12/2024"
                  onChange={onChangeDate}
                  options={[
                     {
                        value: '14/12/2024',
                        label: '14/12/2024'
                     },
                     {
                        value: '15/12/2024',
                        label: '15/12/2024'
                     }
                  ]}
               />
            </Form.Item> */}
         </Form>
      </Modal>
   )
})

export default CreateEmployeeScreen
