import { Input, Form, Button, DatePicker, Select } from 'antd'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../hooks'
import { rule } from '../../helpers/validateRule'
import { PhoneOutlined, UserOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import Images from '../../common/Images'
import { isEmpty } from 'lodash'
import DetailRegister from 'src/common/components/DetailRegister'

export const RegisterWrapper = styled.div`
   height: 100vh;
   height: 100dvh;
   padding: 25px 35px;
   background-image: url(${(props) => props.theme.bg_img.bgNoLogo});
   background-size: cover; /* Ensures the image covers the entire area */
   background-position: center; /* Centers the image */
   background-repeat: no-repeat; /* Prevents tiling */
   background-attachment: fixed;
   overflow: hidden;
   .ant-btn > .ant-btn-loading-icon .anticon {
      margin-right: 10px;
      padding: 0;
   }
   .ant-form-item-label > label {
      color: #fff;
      font-size: 18px;
      height: 30px;
      font-weight: 500;
   }
   .success {
      width: 100%;
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -70%);
   }
   .login-content__body {
      max-height: calc(100dvh - 50px);
      overflow-y: scroll;
      // hide scroll bar
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
         display: none; /* Chrome, Safari, and Opera */
      }
      .login-content__form {
         color: #fff;
         .form__title {
            font-size: 24px;
            text-align: center;
            margin-bottom: 36px;
            font-weight: 600;
         }
         .time-left {
            display: inline-block;
            width: calc(50% - 10px);
         }
         .time-right {
            display: inline-block;
            width: calc(50% - 10px);
            margin-left: 20px !important;
         }
         .action {
            text-align: center;
            width: 100%;
            margin-top: 15%;
         }
      }
   }
`
const ConcertRegister = observer((props: any) => {
   const productStore = useStore('ProductStore')
   const AuthStore = useStore('AuthStore')
   const RegisterStore = useStore('RegisterStore')
   const { t } = useTranslation()
   const [isLoading, setIsLoading] = useState(false)
   const { history } = props
   const [form] = Form.useForm()
   const [registerSuccess, setRegisterSuccess] = useState(false)
   const [detail, setDetail] = useState({})
   const onFinish = async (values: any) => {
      try {
         setIsLoading(true)
         console.log(values)
         const result = await RegisterStore.concertRegister(values)
         if (!isEmpty(result)) {
            setDetail(result.data)
         }
      } catch (error) {
         console.log(error)
      } finally {
         setIsLoading(false)
      }
   }

   return (
      <RegisterWrapper style={{}}>
         <div className="login-content__body">
            {!isEmpty(detail) ? (
               <DetailRegister style={{ color: '#fff' }} item={detail} showTitle />
            ) : (
               <div className="login-content__form">
                  <div className="logo">
                     <img src={Images.logo_event} className="img-responsive" alt="Logo" />
                  </div>
                  <div className="form__title">{'Đăng ký xem ca nhạc'}</div>
                  <Form
                     form={form}
                     name="basic"
                     className="gx-signin-form gx-form-row0 login-form__content"
                     onFinish={onFinish}
                  >
                     <Form.Item label="Họ và tên" rules={[rule.requiredName]} name="name" className="gx-mb-3">
                        <Input placeholder={'Họ và tên'} prefix={<UserOutlined />} maxLength={254} />
                     </Form.Item>
                     <Form.Item
                        label="Số điện thoại"
                        rules={[rule.phoneRule, rule.requiredPhone]}
                        name="phoneNumber"
                        className="gx-mb-3"
                     >
                        <Input type="number" placeholder={'Số điện thoại'} prefix={<PhoneOutlined />} maxLength={30} />
                     </Form.Item>
                     {/* <Form.Item label="Biển số xe" name="licensePlate" className="gx-mb-3">
                        <Input placeholder={'Biển số xe'} prefix={<PhoneOutlined />} maxLength={30} />
                     </Form.Item> */}
                     {/* <Form.Item
                        label="Ngày xem"
                        initialValue="14/12/2024"
                        rules={[{ required: true }]}
                        name="dateRegister"
                        className="gx-mb-3"
                     >
                        <Select
                           className="m-full-width"
                           options={[
                              {
                                 value: '14/12/2024',
                                 label: '14/12/2024'
                              },
                              {
                                 value: '15/12/2024',
                                 label: '15/12/2024'
                              }
                           ]}
                        />
                     </Form.Item> */}
                     <Form.Item className="action">
                        <Button
                           loading={isLoading}
                           htmlType="submit"
                           style={{
                              width: '80%',
                              height: '46px',
                              fontSize: '16px',
                              color: '#fff',
                              fontWeight: 'bold',
                              textTransform: 'uppercase',
                              backgroundColor: '#00C7FF',
                              borderColor: '#00C7FF',
                              borderRadius: '8px',
                              marginBottom: '15px'
                           }}
                        >
                           {'Đăng ký'}
                        </Button>
                     </Form.Item>
                  </Form>
               </div>
            )}
         </div>
      </RegisterWrapper>
   )
})

export default ConcertRegister
