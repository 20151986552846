import { observable, action } from 'mobx'
import { responseDefault } from './store.constants'
import { Request2 } from 'src/common/Request2'
import api from 'src/common/api'
import { PAGE_SIZE, typeFund } from 'src/helpers/constants'

export class CheckinStore {


    @action
    async checkin(id: string, params = {}) {
        const result = await Request2.postWithToken(params, api.checkin.checkin + '/' + id)
    }

    @action
    async checkinConcept(id: string,) {
        const result = await Request2.postWithToken({}, api.checkin.checkinConcept + '/' + id)
        return result
    }

    @action
    async createTicket(params: any = {}) {
        const result = await Request2.postWithToken(params, api.checkin.createTicket)
        return result
    }

}
