import { Route, Switch } from 'react-router-dom'
import NotFoundPage from '../404/NotFoundPage'
import { ROUTER_PATH } from './metaData'
import ProfileScreen from '../profile'
import IntroPage from '../Intro/Intro'
import DefaultNccScreen from '../default/DefaultNccScreen'
const SupplierRouters = (props: any) => {
   return (
      <Switch>
         <Route exact path={`/`} component={DefaultNccScreen} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.IntroPage}`} component={IntroPage} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.ProfileScreen}`} component={ProfileScreen} />
         <Route path="*" component={NotFoundPage} />
      </Switch>
   )
}

export default SupplierRouters
