import bgSignin from 'src/assets/images/bg-signin.png'
import bgNoLogo from 'src/assets/images/bg_no_logo.png'
import bgCounter from 'src/assets/images/bg_counter.png'
const theme = {
   color: {

      primary: '#00C7FF',
      secondary: '#C1C1C1',
      info: '#1847F0',
      success: '#0EC758',
      white: '#ffffff',
      // red
      red_0: 'red',
      // grey
      grey_1: '#5c5c5c',
      // dark
      dark_0: '#323347',
      dark_1: '#949494',
      dark_2: '#272944'
   },
   bg_img: {
      signin_mobi: bgSignin,
      bgNoLogo: bgNoLogo,
      bg_counter: bgCounter,
   }
}
export default theme
