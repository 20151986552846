import { useEffect, useState } from 'react'
import { Modal, Form, Button, Input, Checkbox } from 'antd'
import { observer } from 'mobx-react-lite'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'

import { useStore } from '../../hooks'
import useIsMobile from 'src/hook/hookDevice'

import { QrReader } from 'react-qr-reader';
import { showMessageSuccess } from 'src/helpers/functions'


const QRScanner = observer((props: any) => {
   const { t } = useTranslation()
   const [dataObject, setDataObject] = useState(null);
   const { visible, onOk, onCancel, onCheckin, userSelected } = props
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [checkinAuto, setCheckinAuto] = useState(false)
   const CheckinStore = useStore('CheckinStore')
   const [form] = Form.useForm()

   const isMobile = useIsMobile()

   useEffect(() => {
      // getDataEmployee()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const handleChangeTextSearch = (e: any) => {
      console.log('e.currentTarget.value.trim', e.currentTarget.value.trim());

      if (e.currentTarget.value) {
         try {
            console.log('result', e.currentTarget.value);
            const dt = e.currentTarget.value.trim();
            // @ts-ignore
            const data1 = JSON.parse(dt)
            setDataObject(data1)
            if (checkinAuto) {
               checkinNowAuto(data1)
            }
         } catch (error) {
            console.info(error);
            alert('Vé không hợp lệ!')
            setTimeout(() => {
               setDataObject(null)
            }, 800);
         }
      }
   }
   const checkinNowAuto = async (dataNow: any) => {
      console.log('now');
      if (dataNow) {
         //@ts-ignore
         const update = await CheckinStore.checkinConcept(dataNow?.id as unknown as string)
         if (update) {
            //@ts-ignore
            showMessageSuccess(`Tài khoản ${dataNow?.name} đã checkin thành công!`)
            onCheckin()
            setDataObject(null)
         } else {
            setDataObject(null)
         }
         console.log('update', update, 'dataObject', dataNow);

      } else {
         alert('Vé không hợp lệ!')
         setTimeout(() => {
            setDataObject(null)
         }, 1000);
      }
   }
   const checkinNow = async () => {
      console.log('now');
      if (dataObject) {
         //@ts-ignore
         const update = await CheckinStore.checkinConcept(dataObject?.id as unknown as string)
         if (update) {
            //@ts-ignore
            showMessageSuccess(`Tài khoản ${dataObject?.name} đã checkin thành công!`)
            onCheckin()
            setDataObject(null)
         } else {
            setDataObject(null)
         }
         console.log('update', update, 'dataObject', dataObject);

      } else {
         alert('Vé không hợp lệ!')
         setTimeout(() => {
            setDataObject(null)
         }, 1000);
      }
   }

   const resetQR = () => {
      setDataObject(null)
   }

   const onChangeStatus = (e: any) => {
      setCheckinAuto(e.target.checked)
   }

   return (
      <Modal
         width={isMobile ? '95%' : '68%'}
         visible={visible}
         centered
         title={'[CHỈ DÙNG CHO MÁY QUÉT CHUYÊN DỤNG] Quét QR checkin'}
         // okText={t(GLOBAL_CLIENT.txtCreate)}
         cancelText={t(GLOBAL_CLIENT.huy)}
         onCancel={onCancel}
         footer={[
            <>
               <Button key="back" onClick={onCancel}>
                  Đóng
               </Button>
            </>
         ]}
      // onOk={form.submit}
      >

         {
            dataObject ?
               <div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                     <span style={{ fontSize: 14, color: '#000', marginRight: 16 }}>Khách hàng:</span>

                     {/* @ts-ignore */}
                     <span style={{ fontSize: 14, color: '#000', fontWeight: 'bold' }}>{dataObject?.name || ''}</span>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                     <span style={{ fontSize: 14, color: '#000', marginRight: 16 }}>SDT:</span>

                     {/* @ts-ignore */}
                     <span style={{ fontSize: 14, color: '#000', fontWeight: 'bold' }}>{dataObject?.phone || ''}</span>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 16 }}>
                     <span style={{ fontSize: 14, color: '#000', marginRight: 16 }}>Mã vé:</span>
                     {/* @ts-ignore */}
                     <span style={{ fontSize: 14, color: '#000', fontWeight: 'bold' }}>{dataObject?.code || ''}</span>
                  </div>
                  <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                     <Button onClick={checkinNow} type="primary" className="gx-m-0">
                        Checkin
                     </Button>
                     <Button onClick={resetQR} type="ghost" className="gx-m-2" >
                        Quét lại QR
                     </Button>
                  </div>
               </div>
               :

               <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Checkbox
                     style={{ marginBottom: 12 }}
                     defaultChecked={checkinAuto}
                     onChange={onChangeStatus}
                  >
                     {'Checkin 1 chạm'}
                  </Checkbox>
                  <Input onPressEnter={handleChangeTextSearch} style={{ width: '50%' }} placeholder={t(GLOBAL_CLIENT.search)} allowClear autoFocus />
               </div>
         }
         {/* <p>{dataObject}</p> */}
      </Modal >
   )
})

export default QRScanner
