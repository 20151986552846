import { useEffect, useState } from 'react'
import { Modal, Form, Button, Input, Checkbox } from 'antd'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import useIsMobile from 'src/hook/hookDevice'

import { QrReader } from 'react-qr-reader';
import { showMessageSuccess } from 'src/helpers/functions'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import QRCode from 'react-qr-code'


const QRScanner = observer((props: any) => {
   const { t } = useTranslation()
   const { visible, qrCode, onCancel } = props
   const isMobile = useIsMobile()
   useEffect(() => {
      // getDataEmployee()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   return (
      <Modal
         width={isMobile ? '95%' : '68%'}
         visible={visible}
         centered
         title={'Vé checkin Ca nhạc'}
         // okText={t(GLOBAL_CLIENT.txtCreate)}
         cancelText={t(GLOBAL_CLIENT.huy)}
         onCancel={onCancel}
         footer={[
            <>
               <Button key="back" onClick={onCancel}>
                  Đóng
               </Button>
            </>
         ]}
      // onOk={form.submit}
      >

         <div className="gx-text-center" style={{ marginBottom: '30px' }}>
            <QRCode size={256} style={{ height: 'auto', width: isMobile?'96%':'50%' }} value={qrCode} viewBox={`0 0 256 256`} />
         </div>
      </Modal >
   )
})

export default QRScanner
