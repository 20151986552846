
export const responseDefault = {
    rows: [],
    limit: 0,
    offset: 0,
    count: 0
}

export const responseDefault2 = {
    results: [],
    limit: 0,
    page: 0,
    totalResults: 0
}