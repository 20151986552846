import React from 'react'

import { useEffect, useState } from 'react'
import { Input, Select, Modal, Form, Checkbox, InputNumber, Button } from 'antd'
import { observer } from 'mobx-react-lite'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import DetailRegister from 'src/common/components/DetailRegister'
import useIsMobile from 'src/hook/hookDevice'

const UserDetail = observer((props: any) => {
   const { t } = useTranslation()
   const { item, visible, onOk, onCancel, onlyView } = props
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const isMobile = useIsMobile()

   const renderContent = () => {
      return <DetailRegister style={{ color: '#000' }} item={item} />
   }
   return (
      <Modal
         width={isMobile ? '100%' : '600px'}
         visible={visible}
         centered
         title={t(GLOBAL_CLIENT.detail)}
         okText={t(GLOBAL_CLIENT.edit)}
         cancelText={t(GLOBAL_CLIENT.huy)}
         onCancel={onCancel}
         footer={[
            <>
               <Button key="back" onClick={onCancel}>
                  Đóng
               </Button>
            </>
         ]}
      >
         {renderContent()}
      </Modal>
   )
})

export default UserDetail
