import { Input, Form } from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ERROR_CODES, ROLES, STATUS_LOGIN } from '../../helpers/constants'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useStore } from '../../hooks'
import { rule } from '../../helpers/validateRule'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import {
   accountantMeta,
   adminMeta,
   fulfillmentMeta,
   managerOrderMeta,
   managerStockMeta,
   sellerMeta,
   supplierMeta
} from '../routes/metaData'
import Images from 'src/common/Images'
import { showMessageError } from 'src/helpers/functions'
import { debounce } from 'lodash'
import { AuthenWrapper, LoginWrapper } from 'src/styles/login/Authen.style'
import BaseButton from 'src/common/components/button'
import useIsMobile from 'src/hook/hookDevice'

const LoginScreen = observer((props: any) => {
   const AuthStore = useStore('AuthStore')
   const { t } = useTranslation()
   const [isLoading, setIsLoading] = useState(false)
   const [rememberMe, setRememberMe] = useState(true)
   const { history } = props
   const [form] = Form.useForm()
   const isMobile = useIsMobile()

   useEffect(() => {
      if (AuthStore.isLogin === STATUS_LOGIN.LOGIN_SUCCESS) {
         console.log('====================================')
         console.log('vao dayyyyyyyy')
         console.log('====================================')
         history.push('/')
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [AuthStore.isLogin])

   const onChangeRememberMe = (e: any) => {
      setRememberMe(e.target.checked)
   }

   const onFinishFailed = (errorInfo: any) => {}

   const onFinish = async (values: any) => {
      try {
         setIsLoading(true)
         Object.keys(values)?.forEach((k) => {
            if (typeof values[k] == 'string') return (values[k] = values[k].trim())
         })
         console.log(values)
         const { email, password } = values

         const result = await AuthStore.action_loginUser(email, password, rememberMe)
         if (result?.code === ERROR_CODES.SUCCESS) {
            history.push('/')
         } else if (result?.isAccountNotActive) {
            showMessageError(t(GLOBAL_CLIENT.accountNotActive), () => history.push('/resend-verify-email'))
         }
      } catch (error) {
         // console.log(error)
      } finally {
         setIsLoading(false)
      }
   }

   return (
      <AuthenWrapper>
         {/* <Loading isLoading={isLoading} /> */}

         <div className="login__banner">
            <img src={Images.bg_login} alt="" />
         </div>
         <LoginWrapper className="login__content">
            {/* <StickyChatButton /> */}
            <div className="login-content__body">
               <div className="login-content__logo">
                  <img src={Images.logo_event} alt="" />
               </div>
               <div className="login-content__form">
                  <div className="form__title gx-text-uppercase">{t(GLOBAL_CLIENT.login)}</div>
                  {/* <div className="form__desc">{t(GLOBAL_CLIENT.registerDesc)}</div> */}
                  <Form
                     form={form}
                     name="basic"
                     onFinish={debounce(onFinish, 400)}
                     onFinishFailed={onFinishFailed}
                     className="gx-signin-form gx-form-row0 login-form__content"
                  >
                     {isMobile ? (
                        <>
                           <Form.Item rules={[rule.requiredAccount]} name="email" label="Tên đăng nhập">
                              {/* <Input placeholder={t(GLOBAL_CLIENT.email)} prefix={<UserOutlined />} maxLength={254} /> */}
                              <Input maxLength={254} />
                           </Form.Item>
                           <Form.Item rules={[rule.requiredPassword]} name="password" label="Mật khẩu" style={{ marginBottom: '60px' }}>
                              <Input
                                 type="password"
                                 // placeholder={t(GLOBAL_CLIENT.password)}
                                 // prefix={<LockOutlined />}
                                 maxLength={30}
                              />
                           </Form.Item>
                        </>
                     ) : (
                        <>
                           <Form.Item rules={[rule.requiredAccount]} name="email" >
                              <Input placeholder={"Tên đăng nhập"} prefix={<UserOutlined />} maxLength={254} />
                           </Form.Item>
                           <Form.Item rules={[rule.requiredPassword]} name="password">
                              <Input
                                 type="password"
                                 placeholder={t(GLOBAL_CLIENT.password)}
                                 prefix={<LockOutlined />}
                                 maxLength={30}
                              />
                           </Form.Item>
                        </>
                     )}
                     <Form.Item>
                        <BaseButton size='lg' type="primary" className="gx-mb-0 gx-text-uppercase" >
                           {t(GLOBAL_CLIENT.login)}
                        </BaseButton>
                     </Form.Item>
                  </Form>
               </div>
            </div>
            {/* <div className="login-content__footer">
               <span>{t(GLOBAL_CLIENT.or)}&nbsp;</span>
               <Link to="/signup-creator">{t(GLOBAL_CLIENT.signup)}</Link>
               <Link to="/signup-creator">&nbsp;Creator</Link>
               / <Link to="/signup-designer">Designer</Link>
            </div> */}
         </LoginWrapper>
      </AuthenWrapper>
   )
})

export default LoginScreen
