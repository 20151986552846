import React from 'react'
import { useEffect, useState } from 'react'
import { Input, Select, Modal, Form, InputNumber, Row, Col, Space, Button, DatePicker } from 'antd'
import { observer } from 'mobx-react-lite'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { validateEmail, validatePassword, validatePhone } from '../../helpers/functions'
import { debounce, isEmpty, trim } from 'lodash'
import { BANKS, ROLE, ROLES } from '../../helpers/constants'

import { useStore } from '../../hooks'
import { rule } from 'src/helpers/validateRule'
const { Option } = Select

const formItemLayout = {
   labelCol: {
      xs: { span: 24 },
      sm: { span: 8 }
   },
   wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
   }
}

const { RangePicker } = DatePicker

const ListGiftScreen = observer((props: any) => {
   const { t } = useTranslation()

   const { visible, onOk, onCancel } = props
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [isLoading, setIsLoading] = useState(false)
   const [rangeDate, setRangeDate] = useState<any>(null)
   const [data, setData] = useState<any>()
   const UserStore = useStore('UserStore')
   const [form] = Form.useForm()
   useEffect(() => {
      // getDataEmployee()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const getListGiftCode = async (params: any) => {
      const result = await UserStore.getListGiftCode(params)
      setData(result.join('\n'))
   }

   return (
      <Modal width={'68%'} visible={visible} centered title={t(GLOBAL_CLIENT.create)} onCancel={onCancel}>
         <Row>
            <Col span={24}>
               <Row>
                  <Col xs={24} md={12}>
                     <RangePicker
                        showTime={{ format: 'HH:mm' }}
                        format="DD/MM/YYYY HH:mm"
                        onChange={(value, dateString) => {
                           console.log('Selected Time: ', value)
                           console.log('Formatted Selected Time: ', dateString)
                           setRangeDate(dateString)
                           getListGiftCode({ from: dateString[0], to: dateString[1] })
                        }}
                        onOk={onOk}
                     />
                  </Col>
                  <Col xs={24} md={12}>
                     <div className="text-right">
                        <Space>
                           <Button
                              onClick={() => {
                                 if (!isEmpty(rangeDate)) {
                                    getListGiftCode({ from: rangeDate[0], to: rangeDate[1] })
                                 }
                              }}
                              className="btn-add"
                           >
                              Danh sách quay thưởng
                           </Button>
                        </Space>
                     </div>
                  </Col>
               </Row>
            </Col>
         </Row>
         <Space> </Space>
         <Row>
            <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
               <Button
                  onClick={async () => {
                     await navigator.clipboard.writeText(data)
                  }}
                  className="btn-add"
               >
                  Copy
               </Button>
            </Col>
         </Row>
         <Row>
            <Col span={24}>
               <Input.TextArea value={data} placeholder="Danh sách user"></Input.TextArea>
            </Col>
         </Row>
      </Modal>
   )
})

export default ListGiftScreen
