import { useEffect, useState } from 'react'
import { Table, Space, Pagination, Input, Col, Row } from 'antd'
import { isEmpty, map, size, trim } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../hooks'
import { PAGE_SIZE, STATUS_ACTIVITY } from '../../helpers/constants'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
// import Detail from './Detail'
import useWindowDimensions from '../../common/useWindowDimensions'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import useModal from 'src/helpers/modal/useModal'
import Create from './Create'
import Detail from './Detail'

const AnswersManagement = observer((props: any) => {
   const { t } = useTranslation()
   const ProductStore = useStore('ProductStore')
   const JobDetailStore = useStore('JobDetailStore')
   // const CommonConfigStore = useStore('CommonConfigStore')
   const AuthStore = useStore('AuthStore')
   const { data_getUserInfo } = AuthStore
   // const { designTypesList, productTypesList } = CommonConfigStore
   const [visible, setVisible] = useState(false)
   const [textSearch, set_textSearch] = useState('')
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   // const [isLoading, setIsLoading] = useState(false)
   const { height } = useWindowDimensions()
   const [visibleCreate, setVisibleCreate] = useState(false)
   const [visibleDetail, setVisibleDetail] = useState(false)
   const [visibleFilter, setVisibleFilter] = useState(false)
   const [item, setItem] = useState(null)
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   // const [status, setStatus] = useState(POSITION_INFO.ALL.id)
   // const history = useHistory()
   const [fieldFilter, setFieldFilter] = useState<any>({})
   const [statusObj, setStatusObj] = useState<any>({
      new: [STATUS_ACTIVITY.NEW],
      doing: [STATUS_ACTIVITY.DOING],
      review: '',
      finish: '',
      reject: [STATUS_ACTIVITY.REJECT]
   })
   const { jobTotal } = ProductStore
   const { results, page, totalResults } = ProductStore.anwsers
   const [isOpenEditJobDetail, setIsOpenEditJobDetail] = useState(false)
   const [selectedItem, setSelectedItem] = useState<any>([])

   const [visibleAssign, setVisibleAssign] = useState<any>(false)
   const [itemUser, setItemUser] = useState<any>(null)
   const { confirm } = useModal()

   useEffect(() => {
      getListData(0, {
         ...fieldFilter,
         ...{ name: trim(textSearch) || '' }
         // ...{ status: statusResult }
      })
      // JobsStore.getJobTotal(statusResult)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [textSearch])

   const getListData = async (currentPage: number, param?: any) => {
      try {
         // setIsLoading(true)
         const params = { page, ...param }

         await ProductStore.getAnwsers(params)
      } catch (error) {
         // console.log(error)
      } finally {
         // setIsLoading(false)
      }
   }

   const onChange = (currentPage: number) => {
      // console.log('currentPage;', currentPage, 'currentPage-1', currentPage - 1)

      getListData(currentPage - 1, {
         ...fieldFilter,
         ...{ name: trim(textSearch) || '' } // ...{ status: statusResult }
      })
   }

   const handleChangeTextSearch = (value: any) => {
      set_textSearch(value)
   }

   const handleShowDetail = (item: any) => {
      setItem(item)
      // JobDetailStore.setJobDetail(item)
      setVisibleDetail(true)
   }

   const handleDeleteProduct = (value: any) => {
      confirm({
         title: t(GLOBAL_CLIENT.banmuonxoabanghi),
         icon: <ExclamationCircleOutlined />,
         okText: t(GLOBAL_CLIENT.dongy),
         cancelText: t(GLOBAL_CLIENT.huy),
         onCancel() {},
         async onOk() {
            await ProductStore.deleteQuestions(value?.id)
            if (size(trim(textSearch)) > 0) {
               getListData(0, { ...fieldFilter, ...{ name: trim(textSearch) || '' } })
            } else {
               getListData(0, { ...fieldFilter })
            }
         }
      })
   }
   const handleCreate = () => {
      if (size(trim(textSearch)) > 0) {
         getListData(0, { ...fieldFilter, ...{ name: trim(textSearch) || '' } })
      } else {
         getListData(0, { ...fieldFilter })
      }
      setVisibleCreate(false)
      setItem(null)
   }
   const handleEdit = () => {
      if (size(trim(textSearch)) > 0) {
         getListData(0, { ...fieldFilter, ...{ name: trim(textSearch) || '' } })
      } else {
         getListData(0, { ...fieldFilter })
      }
      setVisibleDetail(false)
      setItem(null)
   }
   const columns = [
      {
         title: 'Người chơi',
         dataIndex: 'name',
         key: 'name',
         width: 120,
         render: (value: any) => {
            return <span className="txtMax2Line">{value}</span>
         }
         // fixed: 'left',
      },
      {
         title: 'Số điện thoại',
         dataIndex: 'phone',
         key: 'phone',
         width: 100,
         render: (value: any) => {
            return <span className="txtMax2Line">{value}</span>
         }
         // fixed: 'left',
      },
      {
         title: 'Điểm số',
         dataIndex: 'score',
         key: 'score',
         width: 100,
         render: (value: any) => {
            return <span className="txtMax2Line">{value}</span>
         }
         // fixed: 'left',
      },
      {
         title: 'Chi tiết các câu hỏi',
         dataIndex: 'questions',
         key: 'questions',
         width: 180,
         render: (value: any, record: any) => {
            return value && value.length > 0 ? (
               map(value, (item) => {
                  return (
                     <span className="txtMax2Line">
                        - {item?.question?.title}
                        {item?.answer === item?.question?.answer ? (
                           <span style={{ fontWeight: '500', color: 'green' }}>(⎷)</span>
                        ) : (
                           <span style={{ fontWeight: '500', color: 'red' }}>(X)</span>
                        )}
                        <br />
                        {item?.answer === item?.question?.answer ? (
                           <span
                              className="txtMax2Line"
                              style={{ paddingLeft: 12, color: 'green' }}
                           >{`Đáp án: ${item?.answer}`}</span>
                        ) : (
                           <>
                              <span
                                 className="txtMax2Line"
                                 style={{ paddingLeft: 12, color: 'red' }}
                              >{`Câu trả lời: ${item?.answer}`}</span>
                              <span
                                 className="txtMax2Line"
                                 style={{ paddingLeft: 12, color: 'green' }}
                              >{`Đáp án: ${item?.answer}`}</span>
                           </>
                        )}
                     </span>
                  )
               })
            ) : (
               <></>
            )
         }
         // fixed: 'left',
      }
      // {
      //    title: 'Câu trả lời',
      //    dataIndex: 'questions',
      //    key: 'questions',
      //    width: 120,
      //    render: (value: any) => {
      //       return value && value.length > 0 ? (
      //          map(value, (item) => {
      //             return <span className="txtMax2Line">- {item}</span>
      //          })
      //       ) : (
      //          <></>
      //       )
      //    }
      // },

      // {
      //    title: 'Câu trả lời',
      //    dataIndex: 'answer',
      //    key: 'createdAt',
      //    width: 120,
      //    render: (value: any) => {
      //       return <span className="txtMax2Line">{value}</span>
      //    }
      // },
      // {
      //    title: 'Action',
      //    key: 'action',
      //    width: 110,
      //    align: 'center' as 'center',
      //    fixed: 'right' as 'right',
      //    render: (text: string, record: any, index: number) => {
      //       return (
      //          <span className="txtMax2Line">
      //             <span onClick={() => handleShowDetail(record)} className="gx-link txtMax2LineHover">
      //                {t(GLOBAL_CLIENT.detail)}
      //             </span>
      //             <Divider type="vertical" />
      //             <span onClick={() => handleDeleteProduct(record)} className="gx-link txtMax2LineHover">
      //                {t(GLOBAL_CLIENT.delete)}
      //             </span>
      //          </span>
      //       )
      //    }
      // }
   ]
   const hideCreate = () => {
      setVisibleCreate(false)
      setItem(null)
   }
   const hideDetail = () => {
      setVisibleDetail(false)
      setItem(null)
   }
   const showCreate = () => {
      setVisibleCreate(true)
   }

   const renderPagination = () => {
      if (isEmpty(results)) {
         return null
      }

      return (
         <div className="containerPagination">
            <Pagination
               onChange={onChange}
               pageSize={PAGE_SIZE}
               current={page}
               showSizeChanger={false}
               total={totalResults}
            />
         </div>
      )
   }

   return (
      <>
         {/* <Loading isLoading={isLoading} /> */}
         <Row>
            <Col span={24}>
               <Row className="row-container-head">
                  <Col xs={24} md={8}>
                     <Input.Search
                        onSearch={handleChangeTextSearch}
                        placeholder={t(GLOBAL_CLIENT.search)}
                        allowClear
                        enterButton
                     />
                  </Col>
                  <Col xs={24} md={2}>
                     {/* <Button type="primary" className="btn-add" onClick={handleFilter}>
                        <i className="icon icon-filter icon-in-button" />
                        {t(GLOBAL_CLIENT.filter)}
                     </Button> */}
                     {/* <Filter onSubmitFilter={onSubmitFilter} onResetFilter={onResetFilter} /> */}
                  </Col>
                  {/* <Col xs={24} md={12}>
                     <Input.Search onSearch={handleChangeTextSearch} placeholder={t(GLOBAL_CLIENT.search)} allowClear />
                  </Col> */}
                  <Col xs={24} md={14}>
                     <div className="text-right">
                        <Space>
                           {/* <Button type="primary" className="btn-add" onClick={showCreate}>
                              <i className="icon icon-add icon-in-button" />

                              {t(GLOBAL_CLIENT.create)}
                           </Button> */}
                           {/* <Button className="btn-add">
                              <span style={{ fontWeight: 'bold', paddingRight: 8 }}>Tổng tiền</span>
                              <span style={{ fontSize: 20, color: '#89AD88', fontWeight: 'bolder' }}>{`${formatCurrency(
                                 DebtsStore.totalDebtsCreator,
                                 true
                              )} VND`}</span>
                           </Button> */}
                        </Space>
                     </div>
                     {/* <div className="text-right">
                        <Space>
                           <Button className="btn-add">
                              <span style={{ fontWeight: 'bold', paddingRight: 8 }}>Tổng SL mẫu</span>
                              <span
                                 style={{ fontSize: 20, color: theme.color.primary, fontWeight: 'bolder' }}
                              >{`${formatCurrency(jobTotal?.count, true)}`}</span>
                           </Button>
                           <Button className="btn-add">
                              <span style={{ fontWeight: 'bold', paddingRight: 8 }}>Tổng tiền</span>
                              <span style={{ fontSize: 20, color: '#89AD88', fontWeight: 'bolder' }}>{`${formatCurrency(
                                 jobTotal?.price,
                                 true
                              )} VND`}</span>
                           </Button>
                        </Space>
                     </div> */}
                  </Col>
               </Row>
               <Space
                  className="total-items"
                  style={{ width: '100%', alignItems: 'center', justifyContent: 'space-between' }}
               >
                  <div>
                     {t(GLOBAL_CLIENT.totalItems)}: {totalResults}
                  </div>
                  {/* <div>
                     <Checkbox
                        style={{ paddingLeft: 4, paddingRight: 4 }}
                        defaultChecked={true}
                        onChange={(e) => onChangeStatus(STATUS_ACTIVITY.NEW, e)}
                     >
                        {'Mới'}
                     </Checkbox>
                     <Checkbox
                        style={{ paddingLeft: 4, paddingRight: 4 }}
                        defaultChecked={true}
                        onChange={(e) => onChangeStatus(STATUS_ACTIVITY.DOING, e)}
                     >
                        {'Đang xử lí'}
                     </Checkbox>
                     <Checkbox
                        style={{ paddingLeft: 4, paddingRight: 4 }}
                        defaultChecked={false}
                        onChange={(e) => onChangeStatus(STATUS_ACTIVITY.REVIEW, e)}
                     >
                        {'Review'}
                     </Checkbox>
                     <Checkbox
                        style={{ paddingLeft: 4, paddingRight: 4 }}
                        defaultChecked={true}
                        onChange={(e) => onChangeStatus(STATUS_ACTIVITY.REJECT, e)}
                     >
                        {t(GLOBAL_CLIENT.cansualai)}
                     </Checkbox>
                     <Checkbox
                        style={{ paddingLeft: 4, paddingRight: 4 }}
                        defaultChecked={false}
                        onChange={(e) => onChangeStatus(STATUS_ACTIVITY.FINISH, e)}
                     >
                        {'Hoàn thành'}
                     </Checkbox>
                  </div> */}
               </Space>
               {/* <Space className="total-items">
                  {t(GLOBAL_CLIENT.totalItems)}: {count}
               </Space> */}

               <Table
                  scroll={{ x: 1500, y: height - 280 }}
                  columns={columns}
                  dataSource={results}
                  rowKey="id"
                  pagination={false}
               />

               {renderPagination()}
            </Col>
         </Row>

         {/* {item && <Detail visible={visibleDetail} item={item} onOk={handleEdit} onCancel={hideDetail} />} */}
         {/* <Filter visible={visibleFilter} onOk={confirmFilter} onCancel={hideFilter} /> */}
         {visibleCreate && <Create visible={visibleCreate} item={item} onOk={handleCreate} onCancel={hideCreate} />}
         {visibleDetail && item && (
            <Detail visible={visibleDetail} item={item} onOk={handleEdit} onCancel={hideDetail} />
         )}
      </>
   )
})

export default AnswersManagement
