import { Route, Switch } from 'react-router-dom'
import NotFoundPage from '../404/NotFoundPage'
import DefaultAdminScreen from '../default/DefaultAdminScreen'
import { ROUTER_PATH } from './metaData'
import ProfileScreen from '../profile'
import IntroPage from '../Intro/Intro'
import QuestionsManagement from '../questions'
import AnswersManagement from '../answers'
import ConfigManagement from '../config-management'
import AccountManagement from '../account-management'
import GiftConfigManagement from '../gift-config-management'
import GiftManagement from '../gift-management'
import MotorInspectionFormManagement from '../motor-inspection-form-management'
import TicketConfigManagement from '../ticket-config-management'
import TicketManagement from '../ticket-management'
import MotorStatusManagement from '../motor-status-management'
import InspectionManagement from '../inspection-management'
import CheckinManagement from '../checkin-management'
import CheckinMusicManagement from '../checkin-music-management'
import VehicleServey from '../authenticate/Servey'
const AdminRouters = (props: any) => {
   return (
      <Switch>
         <Route exact path={`/`} component={DefaultAdminScreen} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.IntroPage}`} component={IntroPage} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.QuestionsManagement}`} component={QuestionsManagement} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.QuestionsManagement}`} component={InspectionManagement} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.CheckinManagement}`} component={CheckinManagement} />
         <Route
            exact
            path={`${props.match.url}${ROUTER_PATH.CheckinMusicManagement}`}
            component={CheckinMusicManagement}
         />
         <Route exact path={`${props.match.url}${ROUTER_PATH.InspectionManagement}`} component={InspectionManagement} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.AnswersManagement}`} component={AnswersManagement} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.ConfigManagementScreen}`} component={ConfigManagement} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.ProfileScreen}`} component={ProfileScreen} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.AccountManagement}`} component={AccountManagement} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.GiftConfigManagement}`} component={GiftConfigManagement} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.GiftManagement}`} component={GiftManagement} />
         <Route
            exact
            path={`${props.match.url}${ROUTER_PATH.MotorInspectionFormManagement}`}
            component={MotorInspectionFormManagement}
         />
         <Route
            exact
            path={`${props.match.url}${ROUTER_PATH.TicketConfigManagement}`}
            component={TicketConfigManagement}
         />
         <Route exact path={`${props.match.url}${ROUTER_PATH.TicketManagement}`} component={TicketManagement} />
         <Route
            exact
            path={`${props.match.url}${ROUTER_PATH.MotorStatusManagement}`}
            component={MotorStatusManagement}
         />
         <Route path="*" component={NotFoundPage} />
      </Switch>
   )
}

export default AdminRouters
