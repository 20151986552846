import React from 'react'
import { useEffect, useState } from 'react'
import { Table, Space, Button, Pagination, Input, Col, Row, Checkbox, Divider, Modal, Image, Select } from 'antd'
import { isEmpty, size, trim } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../hooks'
import { CONFIG_URL, PAGE_SIZE, POSITION_INFO, ROLES, getPage, getRoleString } from '../../helpers/constants'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { Loading } from '../../common'
import Create from './Create'
import Detail from 'src/screens/authenticate/UserDetail'
import useWindowDimensions from '../../common/useWindowDimensions'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import useModal from 'src/helpers/modal/useModal'
import { formatCurrency } from 'src/helpers/functions'
import { CheckinWrapper } from '../authenticate/checkin'
import CardItem from 'src/common/components/CardCheckins/item'
import useIsMobile from 'src/hook/hookDevice'
import Survey from './Survey'
const InspectionManagement = observer((props: any) => {
   const { t } = useTranslation()
   const UserStore = useStore('UserStore')
   const AuthStore = useStore('AuthStore')
   const { data_getUserInfo } = AuthStore
   const [textSearch, set_textSearch] = useState('')
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [isLoading, setIsLoading] = useState(false)
   const { height, width } = useWindowDimensions()
   const [visibleCreate, setVisibleCreate] = useState(false)
   const [visibleDetail, setVisibleDetail] = useState(false)
   const [visibleSurvey, setVisibleSurvey] = useState(false)
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [status, setStatus] = useState(POSITION_INFO.ALL.id)
   const [item, setItem] = useState(null)
   const [fieldFilter, setFieldFilter] = useState<any>({})
   const { confirm } = useModal()

   const { rows, offset, count } = UserStore.listEmployees
   const isMobile = useIsMobile()
   useEffect(() => {
      if (size(trim(textSearch)) > 0) {
         getListData(0, { ...fieldFilter, ...{ keyword: trim(textSearch) } })
      } else {
         getListData(0, { ...fieldFilter })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [textSearch])

   const getListData = async (currentPage: number, param?: any) => {
      try {
         // setIsLoading(true)
         const offset = getPage(currentPage)
         const params = { offset, ...param, type: 1 }
         // console.log('params', params)

         await UserStore.getEmployees(params)
      } catch (error) {
         // console.log(error)
      } finally {
         setIsLoading(false)
      }
   }

   const onChange = (currentPage: number) => {
      if (size(trim(textSearch)) > 0) {
         getListData(currentPage - 1, { ...fieldFilter, ...{ keyword: trim(textSearch) } })
      } else {
         getListData(currentPage - 1, { ...fieldFilter })
      }
   }

   const handleChangeTextSearch = (value: any) => {
      set_textSearch(value)
   }

   const clearDetailInfo = () => {
      setItem(null)
   }

   const setDetailInfo = (value: any) => {
      setItem(value)
   }

   const showDetail = (value: any) => {
      setDetailInfo(JSON.parse(JSON.stringify(value)))
      // console.log('showDetail; ', JSON.parse(JSON.stringify(value)))

      setTimeout(() => {
         setVisibleDetail(true)
      }, 500)
   }

   const handleEdit = () => {
      if (size(trim(textSearch)) > 0) {
         getListData(0, { ...fieldFilter, ...{ keyword: trim(textSearch) } })
      } else {
         getListData(0, { ...fieldFilter })
      }
      setVisibleSurvey(false)
      clearDetailInfo()
   }

   const hideDetail = () => {
      setVisibleDetail(false)
      clearDetailInfo()
   }
   const hideSurvey = () => {
      setVisibleSurvey(false)
      clearDetailInfo()
   }

   const showSurvey = (value: any) => {
      setDetailInfo(JSON.parse(JSON.stringify(value)))
      setVisibleSurvey(true)
   }

   const handleCreate = async () => {
      if (size(trim(textSearch)) > 0) {
         getListData(0, { ...fieldFilter, ...{ name: trim(textSearch) } })
      } else {
         getListData(0, { ...fieldFilter })
      }
      setVisibleCreate(false)
   }

   const handleCancel = () => {
      setVisibleCreate(false)
   }

   const columns = [
      {
         title: t(GLOBAL_CLIENT.fullname),
         dataIndex: 'name',
         key: 'name',
         width: 80,
         render: (value: any) => {
            return <span className="txtMax2Line">{value}</span>
         }
         // fixed: 'left',
      },
      {
         title: t(GLOBAL_CLIENT.phone),
         dataIndex: 'phoneNumber',
         width: 100,
         key: 'phoneNumber',
         render: (value: any) => {
            return <span className="txtMax2Line">{value}</span>
         }
      },
      {
         title: t('Biển kiểm soát'),
         dataIndex: 'licensePlate',
         width: 100,
         key: 'licensePlate',
         render: (value: any) => {
            return <span className="txtMax2Line">{value}</span>
         }
      },
      {
         title: 'Mã quay thưởng',
         dataIndex: 'chiefCode',
         width: 100,
         key: 'chiefCode',
         render: (value: any, record: any) => {
            return <span className="txtMax2Line">{record?.chiefCode?.code}</span>
         }
      },
      {
         title: 'Action',
         key: 'action',
         width: 80,
         align: 'center' as 'center',
         fixed: 'right' as 'right',
         render: (record: any) => {
            return (
               <span className="txtMax2Line">
                  <span onClick={() => showDetail(record)} className="gx-link ">
                     {'Chi tiết'}
                  </span>

                  {isEmpty(record?.checkMotobike?.dataForm) && (
                     <>
                        <Divider type="vertical" />
                        <span className="gx-link" onClick={() => showSurvey(record)}>
                           Kiểm tra xe
                        </span>
                     </>
                  )}

                  {!isEmpty(record?.checkMotobike?.dataForm) && (
                     <>
                        <Divider type="vertical" />
                        <span className="gx-link" onClick={() => showDetail(record)}>
                           Kết quả
                        </span>
                     </>
                  )}
               </span>
            )
         }
      }
   ]

   const renderPagination = () => {
      if (isEmpty(rows)) {
         return null
      }

      return (
         <div className="containerPagination">
            <Pagination
               onChange={onChange}
               pageSize={PAGE_SIZE}
               current={offset / PAGE_SIZE + 1}
               showSizeChanger={false}
               total={count}
            />
         </div>
      )
   }

   return (
      <>
         <Loading isLoading={isLoading} />
         <Row>
            <Col span={24}>
               <Row className="row-container-head">
               <Col xs={24} md={12}>
                     <Input.Search onSearch={handleChangeTextSearch} placeholder={t(GLOBAL_CLIENT.search)} allowClear />
                  </Col>
               </Row>

               <Space
                  className="total-items"
                  style={{ width: '100%', alignItems: 'center', justifyContent: 'space-between' }}
               >
                  <span>
                     {t(GLOBAL_CLIENT.totalItems)}: {count}
                  </span>
               </Space>

               {isMobile ? (
                  <CheckinWrapper style={{}}>
                     {rows?.map((row: any) => {
                        return (
                           <CardItem isCheckMoto item={row} key={row.id}>
                              <div className="gx-flex-column " style={{ gap: '5px' }}>
                                 <Button onClick={() => showDetail?.(row)} type="primary" className="gx-m-0">
                                    Chi tiết
                                 </Button>
                                 {!isEmpty(row?.checkMotobike?.dataForm) && (
                                    <Button type="primary" onClick={() => showDetail(row)} className="gx-m-0">
                                       Kết quả
                                    </Button>
                                 )}
                                 {isEmpty(row?.checkMotobike?.dataForm) && (
                                    <Button type="primary" onClick={() => showSurvey(row)} className="gx-m-0">
                                       Kiểm tra xe
                                    </Button>
                                 )}
                              </div>
                           </CardItem>
                        )
                     })}
                  </CheckinWrapper>
               ) : (
                  <Table
                     scroll={{ x: 1500, y: height - 280 }}
                     columns={columns}
                     dataSource={rows}
                     rowKey="id"
                     pagination={false}
                  />
               )}

               {renderPagination()}
            </Col>
         </Row>
         {visibleDetail && item && (
            <Detail
               visible={visibleDetail}
               item={item}
               onOk={handleEdit}
               onCancel={hideDetail}
               vehicleView
               showSurvey={showSurvey}
            />
         )}
         {visibleSurvey && (
            <Survey
               visible={visibleSurvey}
               item={item}
               onOk={handleEdit}
               onCancel={hideSurvey}
               showSurvey={() => setVisibleSurvey(true)}
            />
         )}
      </>
   )
})

export default InspectionManagement
