import { useState, useEffect } from 'react'

const useIsMobile = (breakpoint = 768) => {
   const [isMobile, setIsMobile] = useState(false)

   useEffect(() => {
      const updateScreenSize = () => {
         setIsMobile(window.innerWidth <= breakpoint)
      }

      updateScreenSize()
      window.addEventListener('resize', updateScreenSize)

      return () => window.removeEventListener('resize', updateScreenSize)
   }, [breakpoint])

   return isMobile
}

export default useIsMobile
