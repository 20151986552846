import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import i18n from '../../helpers/i18n'

export const adminMeta = [
   {
      label: i18n.t(GLOBAL_CLIENT.mAccount),
      path: '/m-account'
   },
   {
      label: 'Checkin',
      path: '/m-checkin'
   },
   {
      label: 'Kiểm tra xe',
      path: '/m-inspect'
   },
   {
      label: 'Checkin ca nhạc',
      path: '/m-checkin-music'
   }
   // {
   //    label:'Tình trạng xe',
   //    path: '/m-status'
   // },
   // {
   //    label: 'Quản lý vé',
   //    path: '/m-ticket'
   // },
   // {
   //    label :'Quản lý quà',
   //    path: '/m-gift'
   // },
   // {
   //    label: 'Cấu hình quà',
   //    path: '/m-gift-config'
   // },
   // {
   //    label: 'Cấu hình vé',
   //    path: '/m-ticket-config'
   // },
   // {
   //    label: "Danh sách câu hỏi",
   //    path: '/m-questions'
   // },
   // {
   //    label: "Danh sách câu trả lời",
   //    path: '/m-answers'
   // },
   // {
   //    label: i18n.t(GLOBAL_CLIENT.mConfig),
   //    path: '/m-config'
   // }
]
export const sellerMeta = [
   {
      label: i18n.t(GLOBAL_CLIENT.products),
      path: '/list-products'
   }
]
export const supplierMeta = [
   {
      label: 'Trang chủ',
      path: '/home'
   }
]
export const fulfillmentMeta = [
   {
      label: 'Trang chủ',
      path: '/home'
   }
]
export const managerOrderMeta = [
   {
      label: 'Trang chủ',
      path: '/home'
   }
]
export const managerStockMeta = [
   {
      label: 'Trang chủ',
      path: '/home'
   }
]

export const accountantMeta = [
   {
      label: 'Trang chủ',
      path: '/home'
   }
]

export const supporterMeta = [
   {
      label: 'Trang chủ',
      path: '/home'
   }
]

export const userMeta = [
   {
      label: 'Trang chủ',
      path: '/home'
   }
]

export const ROUTER_PATH = {
   // Admin
   ConfigManagementScreen: 'm-config',
   QuestionsManagement: 'm-questions',
   AnswersManagement: 'm-answers',
   SellerManagementScreen: 'm-seller',
   FulfillmentManagementScreen: 'm-fulfillment',
   TagManagementScreen: 'm-tags',
   SupplierManagement: 'm-supplier',
   PriceManagement: 'm-price',
   EmployeeManagementScreen: 'm-employee',
   AccountManagementScreen: 'm-account',
   CreateEmployeeScreen: 'm-employee/create',
   ProductsManagement: 'm-products',
   ReportManagement: 'm-report',
   OrderManagement: 'm-order',
   WarehouseManagement: 'm-warehouse',
   FundManagement: 'm-fund',
   ListProductsManagement: 'list-products',
   IntroPage: 'home',

   ProfileScreen: 'profiles',
   ResetPassword: 'reset-password',
   AccountManagement: 'm-account',
   GiftConfigManagement: 'm-gift-config',
   GiftManagement: 'm-gift',
   MotorInspectionFormManagement: 'm-inspect-form',
   TicketConfigManagement: 'm-ticket-config',
   TicketManagement: 'm-ticket',
   MotorStatusManagement: 'm-status',
   InspectionManagement: 'm-inspect',
   CheckinManagement: 'm-checkin',
   CheckinMusicManagement: 'm-checkin-music'
}
