import React from 'react'
import { useEffect, useState } from 'react'
import { Table, Space, Button, Pagination, Input, Col, Row, Checkbox, Divider, Modal, Image, Select } from 'antd'
import { isEmpty, size, trim } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../hooks'
import { CONFIG_URL, PAGE_SIZE, POSITION_INFO, ROLES, getPage, getRoleString } from '../../helpers/constants'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { Loading } from '../../common'
import Create from './Create'
import Detail from 'src/screens/authenticate/UserDetail'
import useWindowDimensions from '../../common/useWindowDimensions'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import useModal from 'src/helpers/modal/useModal'
import { formatCurrency } from 'src/helpers/functions'
import useIsMobile from 'src/hook/hookDevice'
import { CheckinWrapper } from '../authenticate/checkin'
import CardCheckins from 'src/common/components/CardCheckins'
import CardItem from 'src/common/components/CardCheckins/item'
import ListGiftScreen from './ListGift'
const AccountManagement = observer((props: any) => {
   const { t } = useTranslation()
   const UserStore = useStore('UserStore')
   const AuthStore = useStore('AuthStore')
   const { data_getUserInfo } = AuthStore
   const [textSearch, set_textSearch] = useState('')
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [isLoading, setIsLoading] = useState(false)
   const { height, width } = useWindowDimensions()
   const [visibleCreate, setVisibleCreate] = useState(false)
   const [visibleListGiftCode, setVisibleListGiftCode] = useState(false)
   const [visibleDetail, setVisibleDetail] = useState(false)
   const [isTransactionHistoryModalOpen, setIsTransactionHistoryModalOpen] = useState(false)
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [status, setStatus] = useState(POSITION_INFO.ALL.id)
   const [item, setItem] = useState(null)
   const [fieldFilter, setFieldFilter] = useState<any>({})
   const { confirm } = useModal()
   const CheckinStore = useStore('CheckinStore')
   const { rows, offset, count } = UserStore.listEmployees

   const isMobile = useIsMobile()

   useEffect(() => {
      if (size(trim(textSearch)) > 0) {
         getListData(0, { ...fieldFilter, ...{ keyword: trim(textSearch) } })
      } else {
         getListData(0, { ...fieldFilter })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [textSearch, fieldFilter])

   const getListData = async (currentPage: number, param?: any) => {
      try {
         // setIsLoading(true)
         const offset = getPage(currentPage)
         const params = { offset, ...param }
         // console.log('params', params)

         await UserStore.getEmployees(params)
      } catch (error) {
         // console.log(error)
      } finally {
         setIsLoading(false)
      }
   }

   const onChange = (currentPage: number) => {
      if (size(trim(textSearch)) > 0) {
         getListData(currentPage - 1, { ...fieldFilter, ...{ keyword: trim(textSearch) } })
      } else {
         getListData(currentPage - 1, { ...fieldFilter })
      }
   }

   const handleChangeTextSearch = (value: any) => {
      set_textSearch(value)
   }

   const clearDetailInfo = () => {
      setItem(null)
   }

   const setDetailInfo = (value: any) => {
      setItem(value)
   }

   const handleUpdateWin = async (value: any) => {
      await UserStore.updateWin(value.id)
      if (size(trim(textSearch)) > 0) {
         getListData(0, { ...fieldFilter, ...{ name: trim(textSearch) } })
      } else {
         getListData(0, { ...fieldFilter })
      }
   }

   const showDetail = (value: any) => {
      setDetailInfo(JSON.parse(JSON.stringify(value)))
      // console.log('showDetail; ', JSON.parse(JSON.stringify(value)))

      setTimeout(() => {
         setVisibleDetail(true)
      }, 500)
   }

   const hideDetail = () => {
      setVisibleDetail(false)
      clearDetailInfo()
   }

   const showCreate = () => {
      setVisibleCreate(true)
   }

   const showListGift = () => {
      setVisibleListGiftCode(true)
   }

   const hideListGift = () => {
      setVisibleListGiftCode(false)
   }

   const handleCreate = async () => {
      if (size(trim(textSearch)) > 0) {
         getListData(0, { ...fieldFilter, ...{ name: trim(textSearch) } })
      } else {
         getListData(0, { ...fieldFilter })
      }
      setVisibleCreate(false)
   }

   const handleCancel = () => {
      setVisibleCreate(false)
   }

   const handleDelete = (value: any) => {
      confirm({
         title: t(GLOBAL_CLIENT.banmuonxoabanghi),
         icon: <ExclamationCircleOutlined />,
         okText: t(GLOBAL_CLIENT.dongy),
         cancelText: t(GLOBAL_CLIENT.huy),
         onCancel() {},
         async onOk() {
            await UserStore.deleteEmployees(value?.id)
            if (size(trim(textSearch)) > 0) {
               getListData(0, { ...fieldFilter, ...{ name: trim(textSearch) } })
            } else {
               getListData(0, { ...fieldFilter })
            }
         }
      })
   }

   const onCheckin = async (id: string) => {
      try {
         await CheckinStore.checkin(id)
         if (size(trim(textSearch)) > 0) {
            getListData(0, { ...fieldFilter, ...{ name: trim(textSearch) } })
         } else {
            getListData(0, { ...fieldFilter })
         }
         hideDetail()
      } catch (error) {}
   }

   const columns = [
      {
         title: t(GLOBAL_CLIENT.fullname),
         dataIndex: 'name',
         key: 'name',
         width: 80,
         render: (value: any) => {
            return <span className="txtMax2Line">{value}</span>
         }
         // fixed: 'left',
      },
      {
         title: t(GLOBAL_CLIENT.phone),
         dataIndex: 'phoneNumber',
         width: 100,
         key: 'phoneNumber',
         render: (value: any) => {
            return <span className="txtMax2Line">{value}</span>
         }
      },
      {
         title: t('Biển kiểm soát'),
         dataIndex: 'licensePlate',
         width: 100,
         key: 'licensePlate',
         render: (value: any) => {
            return <span className="txtMax2Line">{value}</span>
         }
      },
      {
         title: 'Mã quay thưởng',
         width: 80,
         key: 'chiefCode',
         render: (value: boolean, record: any) => {
            return (
               <span style={{ color: record?.chiefCode?.isWin ? 'red' : undefined }} className="txtMax2Line">
                  {record?.chiefCode?.code || ''}
               </span>
            )
         }
      },
      {
         title: 'Action',
         key: 'action',
         width: 80,
         align: 'center' as 'center',
         fixed: 'right' as 'right',
         render: (record: any) => {
            return (
               <span className="txtMax2Line">
                  <span onClick={() => showDetail(record)} className="gx-link ">
                     {'Chi tiết'}
                  </span>
                  {!!!record.checkin && (
                     <>
                        <Divider type="vertical" />
                        <span onClick={() => onCheckin(record.id)} className="gx-link">
                           {'Checkin'}
                        </span>
                     </>
                  )}
                  {record?.chiefCode && !record?.chiefCode?.isWin && (
                     <>
                        <Divider type="vertical" />
                        <span onClick={() => handleUpdateWin(record)} className="gx-link ">
                           {'Thắng cuộc'}
                        </span>
                     </>
                  )}
               </span>
            )
         }
      }
   ]

   const renderPagination = () => {
      if (isEmpty(rows)) {
         return null
      }

      return (
         <div className="containerPagination">
            <Pagination
               onChange={onChange}
               pageSize={PAGE_SIZE}
               current={offset / PAGE_SIZE + 1}
               showSizeChanger={false}
               total={count}
            />
         </div>
      )
   }

   return (
      <>
         <Loading isLoading={isLoading} />

         <Row>
            <Col span={24}>
               <Row className="row-container-head">
                  <Col xs={12} md={8}>
                     <Input.Search onSearch={handleChangeTextSearch} placeholder={t(GLOBAL_CLIENT.search)} allowClear />
                  </Col>
                  <Col xs={12} md={16}>
                     <div className="text-right">
                        <Space>
                           <Button type="primary" className="btn-add" onClick={showListGift}>
                              DS quay thưởng
                           </Button>
                        </Space>
                     </div>
                  </Col>
               </Row>

               <Space
                  className="total-items"
                  style={{ width: '100%', alignItems: 'center', justifyContent: 'space-between' }}
               >
                  <span>
                     {t(GLOBAL_CLIENT.totalItems)}: {count}
                  </span>
               </Space>
               {isMobile ? (
                  <CheckinWrapper style={{}}>
                     {rows.map((row: any) => {
                        return (
                           <CardItem item={row} key={row.id}>
                              <div className="gx-flex-column" style={{ gap: '5px' }}>
                                 <Button onClick={() => showDetail?.(row)} type="primary" className="gx-m-0">
                                    Chi tiết
                                 </Button>
                                 {!!!row?.checkin && (
                                    <Button type="primary" onClick={() => onCheckin(row.id)} className="gx-m-0">
                                       Checkin
                                    </Button>
                                 )}
                              </div>
                           </CardItem>
                        )
                     })}
                  </CheckinWrapper>
               ) : (
                  <Table
                     scroll={{ x: 1500, y: height - 280 }}
                     columns={columns}
                     dataSource={rows}
                     rowKey="id"
                     pagination={false}
                  />
               )}

               {renderPagination()}
            </Col>
         </Row>
         {visibleCreate && <Create visible={visibleCreate} onOk={handleCreate} onCancel={handleCancel} />}
         {visibleListGiftCode && (
            <ListGiftScreen visible={visibleListGiftCode} onOk={showListGift} onCancel={hideListGift} />
         )}

         {visibleDetail && item && (
            <Detail visible={visibleDetail} item={item} onCancel={hideDetail} onCheckin={onCheckin} />
         )}
      </>
   )
})

export default AccountManagement
