import { Input, Form, Button, DatePicker, Select, Card } from 'antd'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../hooks'
import { rule } from '../../helpers/validateRule'
import { PhoneOutlined, UserOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import CardCheckins from 'src/common/components/CardCheckins'

export const CheckinWrapper = styled.div`
   // height: 100vh;
   // height: 100dvh;
   //    background-size: cover; /* Ensures the image covers the entire area */
   //    background-position: center; /* Centers the image */
   //    background-repeat: no-repeat; /* Prevents tiling */
   .checkin-content__form {
      .ant-card-body {
         padding: 10px;
      }
   }

   @media (min-width: 390px) {
      .login-content__form {
         .action {
            bottom: 100px;
         }
      }
   }
   @media (max-width: 360px) {
      .login-content__form {
         .action {
            bottom: 43px;
         }
      }
   }
   @media (max-width: 344px) {
      .login-content__form {
         .action {
            bottom: 100px;
         }
      }
   }
`
const Checkin = observer((props: any) => {
   const productStore = useStore('ProductStore')
   const AuthStore = useStore('AuthStore')
   const { t } = useTranslation()
   const [isLoading, setIsLoading] = useState(false)
   const { history } = props
   const [form] = Form.useForm()
   const list = [
      {
         name: 'Nguyễn Văn A',
         phone: '0983342324',
         carNumber: '98A-12345'
      },
      {
         name: 'Nguyễn Văn B',
         phone: '0983342325',
         carNumber: '98B-12345'
      },
      {
         name: 'Nguyễn Văn C',
         phone: '0983342326',
         carNumber: '98C-12345'
      },
      {
         name: 'Nguyễn Văn D',
         phone: '0983342327',
         carNumber: '98D-12345'
      },
      {
         name: 'Nguyễn Văn E',
         phone: '0983342328',
         carNumber: '98E-12345'
      },
      {
         name: 'Nguyễn Văn F',
         phone: '0983342329',
         carNumber: '98F-12345'
      },
      {
         name: 'Nguyễn Văn G',
         phone: '0983342330',
         carNumber: '98G-12345'
      }
   ]
   const onFinish = async (values: any) => {
      try {
         setIsLoading(true)
         const { name, phone } = values
         productStore.setUserInfo({ name, phone })
         await productStore.getQuestionsForUser()
         history.push('/game')
      } catch (error) {
         console.log(error)
      } finally {
         setIsLoading(false)
      }
   }
   const onChangeDate = (date: any) => {
      console.log(date)
   }

   return (
      <CheckinWrapper style={{}}>
         <div className="checkin-content__body">
            <Input.Search placeholder={'Số điện thoại'} allowClear />
            {/* <CardCheckins listCard={list} style={{ maxHeight: 'calc(100dvh - 78px)', overflowX: 'scroll' }}>
               <Button style={{ margin: '0' }} type="primary">
                  Checkin
               </Button>
            </CardCheckins> */}
            <div></div>
         </div>
      </CheckinWrapper>
   )
})

export default Checkin
