import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useStore } from '../../hooks'
import styled from 'styled-components'
import CounterImg from 'src/assets/images/svgviewer-react-output'
export const RegisterWrapper = styled.div`
   height: 100vh;
   height: 100dvh;
   padding: 25px 35px;
   background-image: url(${(props) => props.theme.bg_img.bg_counter});
   background-size: cover; /* Ensures the image covers the entire area */
   background-position: center; /* Centers the image */
   background-repeat: no-repeat; /* Prevents tiling */
   .ant-btn > .ant-btn-loading-icon .anticon {
      margin-right: 10px;
      padding: 0;
   }

   .counter-body {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .counter-body__content {
         display: flex;
         align-items: center;
         flex-direction: column;
         color: #fff;
         font-size: 20px;
         font-weight: 500;
         > span {
            display: block;
            &:first-child {
               margin-bottom: 30px;
            }

            &:last-child {
               font: 800 40px system-ui;
            }
         }
      }
   }
`
const VehicleCounter = observer((props: any) => {
   const UserStore = useStore('UserStore')
   const [isLoading, setIsLoading] = useState(false)
   const { history } = props
   const [vehicleDone, setVehicleDone] = useState({
      old: 0,
      new: 0
   })
   const [vehicleTotal, setVehicleTotal] = useState({
      old: 0,
      new: 0
   })
   useEffect(() => {
      // Call the API immediately when the component mounts
      // fetchData()
      getData()
      // Set an interval to call the API every minute (60000 milliseconds)
      const intervalId = setInterval(() => getData(), 60000) // 1 minute

      // Clean up the interval when the component unmounts
      return () => clearInterval(intervalId)
   }, []) // Empty dependency array means this effect runs only once when the component mounts

   const getData = async () => {
      try {
         const result = await UserStore.getUserCount()
         if (result) {
            setVehicleDone((prev) => {
               return {
                  old: prev.new,
                  new: result
               }
            })
         }
      } catch (error) {}
   }

   return (
      <RegisterWrapper style={{}}>
         <div className="counter-body">
            <div className="counter-body__content">
               <CounterImg width={'52vw'} counterOld={vehicleDone.old} counterNew={vehicleDone.new} />
            </div>
         </div>
      </RegisterWrapper>
   )
})

export default VehicleCounter
