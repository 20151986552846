import { observable, action } from 'mobx'
import { ERROR_CODES, LOCAL_STORAGE, PAGE_SIZE, STATUS_LOGIN } from '../helpers/constants'
import api from '../common/api'
import { Request2 } from 'src/common/Request2'
import { showMessageError } from 'src/helpers/functions'
import { responseDefault } from './store.constants'
import { isEmpty } from 'lodash'

export class AuthStore {
   @observable navCollapsed: boolean = true
   @observable width_screen: number = window.innerWidth
   @observable token: string = ''
   @observable isLogin: number = STATUS_LOGIN.WAIT_LOGIN
   @observable data_listCity: any = []
   @observable data_listDistrict: any = []
   @observable data_listVillage: any = []
   @observable data_role: string = ''

   @observable data_getUserInfo: any = {}
   @observable transactionHistory: any = { ...responseDefault }

   @observable data_detailUser: any = {}
   @observable isOpenAddJobDetail: boolean = false

   @action
   async action_OpenAddJobDetail() {
      this.isOpenAddJobDetail = true
   }

   @action
   async action_HideAddJobDetail() {
      this.isOpenAddJobDetail = false
   }

   @action
   async action_openSidebar() {
      this.navCollapsed = !this.navCollapsed
   }

   @action
   async action_closeSidebar() {
      this.navCollapsed = !this.navCollapsed
   }

   @action
   async action_loginUser(email: string, password: string, rememberMe: any) {
      const body = {
         email,
         password
      }

      const result = await Request2.post(body, api.auth.login)

      console.log('====================================');
      console.log("result", result);
      console.log('====================================');
      if (result?.code === ERROR_CODES.SUCCESS) {
         const dataAuth = {
            tokens: result?.data?.tokens
         }

         console.log('====================================');
         console.log("result?.data?.tokens", dataAuth);
         console.log('====================================');

         // await localStorage.setItem(LOCAL_STORAGE.REMEMBER_ME, JSON.stringify(rememberMe))

         localStorage.setItem(LOCAL_STORAGE.DATA_AUTH, JSON.stringify(dataAuth))
         localStorage.setItem(LOCAL_STORAGE.USER_INFOR, JSON.stringify(result?.data?.account))
         // if (rememberMe === true) {
         //    await localStorage.setItem(LOCAL_STORAGE.DATA_AUTH, JSON.stringify(dataAuth))
         // } else {
         //    await sessionStorage.setItem(LOCAL_STORAGE.DATA_AUTH, JSON.stringify(dataAuth))
         // }
         this.isLogin = STATUS_LOGIN.LOGIN_SUCCESS
         this.data_getUserInfo = result?.data?.account
         this.data_role = result?.data?.account?.role

         // console.log('data_getUserInfo ', this.data_getUserInfo)

         // return result?.data
      }

      return result
   }
   @action
   async action_register(params: any, role: any) {
      const result = await Request2.post({ ...params, ...role }, api.auth.register)
      return result
   }

   @action
   async action_updateProfiles(params: any) {
      const result = await Request2.putWithToken({ ...params }, api.user.updateProfile)
      if (result) {
         // this.data_getUserInfo = result?.data
         this.action_getProfile() //check tạm để cho chạy đúng
         return result
      }
      return false
   }

   @action
   async action_updateAvatar(file: any) {
      const result = await Request2.uploadFile(file, api.upload.uploadFile)
      if (result?.code !== ERROR_CODES.SUCCESS) return false
      const res = await this.action_updateProfiles({ avatar: result?.data?.filename })
      if (res?.code === ERROR_CODES.SUCCESS) {
         return res
      }
      return false
   }

   @action
   async action_uploadImage(fileName: any, fileData: any) {
      // const DOMAIN = `${CONFIG_URL.SERVICE_URL}/${WsCode.uploadImg}`;

      // const json = {
      //    fileName,
      //    fileData
      // }

      // const result = await Request.post(WsCode.uploadImg, json, DOMAIN);
      const result = {
         url: ''
      }
      if (result) {
         return result.url
      }

      return false
   }

   @action
   async action_forgotPassword(params: any) {
      // console.log(params)
      const result = await Request2.post({ ...params }, api.auth.forgotPassword)
      return result
   }

   @action
   async action_logout(history: any) {
      await localStorage.removeItem(LOCAL_STORAGE.DATA_AUTH)
      await sessionStorage.removeItem(LOCAL_STORAGE.DATA_AUTH)
      this.data_role = ''
      this.isLogin = STATUS_LOGIN.NOT_LOGIN

      history?.push('/signin')
   }

   @action
   async action_getRole(history?: any) {
      // const result = await Request2.getWithToken(api.user.getProfile)
      // if (!result) {
      //    this.data_getUserInfo = {}
      //    this.data_role = ''
      //    this.isLogin = STATUS_LOGIN.NOT_LOGIN
      //    await localStorage.removeItem(LOCAL_STORAGE.DATA_AUTH)
      //    history.push('/signin')
      //    return result
      // } else {
      //    console.log('222result action_getProfile', result);
      //    this.data_getUserInfo = result
      //    this.data_role = result?.role || ''
      //    return true
      // }
      if (isEmpty(this.data_getUserInfo)) {
         return false
      } else {
         this.data_role = this.data_getUserInfo?.role
         return this.data_getUserInfo
      }
   }

   @action
   async action_getProfile() {
      const result = await Request2.getWithToken(api.user.getProfile)
      if (result) {
         console.log('result action_getProfile', result);
         this.data_getUserInfo = result
         this.data_role = result?.role
         return true
      }
   }
   @action
   async getTransaction(params: any = {}) {
      const result = await Request2.getWithToken(params?.accountId ? api.user.userTransaction : api.user.transaction, { ...params, ...{ limit: PAGE_SIZE } })
      if (result) {
         this.transactionHistory = result
         return true
      }
   }
   @action
   async action_verifyEmail(params: any) {
      try {
         let client = await Request2.header()
         const res = await client.post(`${api.auth.verifyEmail}?token=${params}`, {})
         return res?.data
      } catch (error: any) {
         if (error.message === 'Network Error') {
            showMessageError('Không có kết nối. Vui lòng thử lại')
         } else if (error.message.indexOf('timeout of') !== -1) {
         } else if (error.response?.data?.message === 'Error: Account not verify') {
            return { isAccountNotActive: true }
         } else {
            showMessageError(error.response?.data?.message)
         }
      }
   }

   @action
   async action_resendVerifyEmail(params: any) {
      const result = await Request2.post(params, api.auth.sendEmail)
      return result
   }

   @action
   async action_resetPassword(params: any, token: string | null) {
      const result = await Request2.post(params, `${api.auth.resetPassword}?token=${token}`)
      return result
   }
}
