import { message, Modal } from 'antd'
import moment from 'moment'
import {
   CONFIG_URL,
   DATE_TIME_FORMAT_BACKEND,
   COMMON_STATUS,
   DATE_TIME_FORMAT_CLIENT,
   DATE_FORMAT_CLIENT,
   GENDER,
   DATE_FORMAT_BACKEND,
   ROLE_ID,
   TAB_SIZE,
   NUMBER_USD_FORMAT_SALARY_CLIENT,
   NUMBER_FORMAT_SALARY_CLIENT,
   ZONE_CLASS
} from './constants'
import { GLOBAL_CLIENT } from './GlobalClient'
import { find, get, replace, toLower, size } from 'lodash'
import numeral from 'numeral'

export const showMessageError = (msg, onOk) => {
   Modal.error({
      content: msg,
      onOk: onOk || null,
      className: 'custom-btn__modal'
   })
}

export const showMessageSuccess = (msg, onOk) => {
   Modal.success({
      content: msg,
      onOk: onOk,
      className: 'custom-btn__modal'
   })
}

export const showMessageInfo = (msg) => {
   message.info(msg)
}

export const showImage = (path) => {
   return `${CONFIG_URL.SERVICE_URL}${path}`
}

export const to_slug = (str) => {
   // Chuyển hết sang chữ thường
   str = str.toLowerCase()

   // xóa dấu
   str = str.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, 'a')
   str = str.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, 'e')
   str = str.replace(/(ì|í|ị|ỉ|ĩ)/g, 'i')
   str = str.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, 'o')
   str = str.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, 'u')
   str = str.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, 'y')
   str = str.replace(/(đ)/g, 'd')

   // Xóa ký tự đặc biệt
   str = str.replace(/([^0-9a-z-\s])/g, '')

   // Xóa khoảng trắng thay bằng ký tự -
   str = str.replace(/(\s+)/g, '-')

   // xóa phần dự - ở đầu
   str = str.replace(/^-+/g, '')

   // xóa phần dư - ở cuối
   str = str.replace(/-+$/g, '')

   // return
   return str
}

export const getValueLodash = (data, value) => {
   return get(data, value)
}

export const findObjectFromArrayLodash = (array, objectFind) => {
   return find(array, objectFind)
}

export const showModalSuccess = (mesage, onOK) => {
   Modal.success({
      content: mesage,
      onOk: onOK || null
   })
}

export const getDateTimeShow = (date) => {
   if (!date) {
      return null
   }

   const result = moment(date, DATE_TIME_FORMAT_BACKEND).format(DATE_TIME_FORMAT_CLIENT)

   if (result == 'Invalid date') {
      return null
   }

   return result
}

export const getDateShow = (date) => {
   if (!date) {
      return null
   }

   return moment(date).format(DATE_FORMAT_CLIENT)
}

export const showPrice = (value) => {
   if (value) {
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
   }
   return 0
}

export const getDateClientPicker = (value) => {
   if (!value) {
      return null
   }

   return moment(value, DATE_FORMAT_BACKEND)
}

export const getStatusClientShow = (status) => {
   if (status === COMMON_STATUS.ACTIVE) {
      return true
   } else if (status === COMMON_STATUS.INACTIVE) {
      return false
   }

   return null
}

export const getDateTimeBackend = (value) => {
   if (!value) {
      return null
   }

   return moment(value).format(DATE_TIME_FORMAT_BACKEND)
}

export const getStatusBackend = (status) => {
   if (status === true || status === 1) {
      return COMMON_STATUS.ACTIVE
   } else if (status === false || status === 0) {
      return COMMON_STATUS.INACTIVE
   }

   return null
}

export const readFileToBase64 = (file) => {
   return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
   })
}

export const getNameTypeDoc = (arrDocs, id) => {
   let obj = findObjectFromArrayLodash(arrDocs, { id })

   if (obj) {
      return obj.name
   }

   return null
}

export const getTextGender = (gender) => {
   if (gender === GENDER.MALE) {
      return GLOBAL_CLIENT.male
   } else if (gender === GENDER.FEMALE) {
      return GLOBAL_CLIENT.female
   }

   return ''
}

export const getTextRoleId = (roleId) => {
   switch (roleId) {
      case ROLE_ID.ADMIN:
         return GLOBAL_CLIENT.admin

      case ROLE_ID.PARTNER:
         return GLOBAL_CLIENT.partner

      default:
         return ''
   }
}

export const getOffsetLayout = (width) => {
   return width < TAB_SIZE ? 0 : 6
}

export function validatePassword(value) {
   if (!value || value?.length < 8 || !value.match(/\d/) || !value.match(/[a-zA-Z]/)) {
      return false
   }
   return true
}

export const validateEmail = (email) => {
   const pattern =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
   if (email && email !== '') {
      return pattern.test(toLower(`${email}`))
   }
   return false
}

export const validatePhone = (phone) => {
   const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
   if (phone && phone !== '' && size(phone) === 10) {
      return pattern.test(phone)
   }
   return false
}

export const validatePhoneUS = (phone) => {
   const pattern = /^\d{10}$/

   if (phone && phone !== '') {
      return pattern.test(phone)
   }
   return false
}

export const validateUrl = (url) => {
   const pattern = /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g;
   if (url && url !== '') {
      return pattern.test(url)
   }
   return false
}

export function removeToneSign(text) {
   let label = toLower(text)
   label = replace(label, /à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
   label = replace(label, /è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
   label = replace(label, /ì|í|ị|ỉ|ĩ/g, 'i')
   label = replace(label, /ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
   label = replace(label, /ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
   label = replace(label, /ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
   label = replace(label, /đ/g, 'd')
   return label
}

export const formatCurrency = (value, isVND = false) => {
   let result = numeral(value).format(NUMBER_USD_FORMAT_SALARY_CLIENT) + ''
   if (isVND) {
      result = numeral(value).format(NUMBER_FORMAT_SALARY_CLIENT) + ''
   }
   // else {
   //   let i = 0;
   //   while ((result[result?.length - 1] === '0' || result[result?.length - 1] === '.') && i < 10) {
   //     result = result.substring(0, result?.length - 1);
   //     i++;
   //   }
   // }
   return result
}

export const capitalizeFirstLetter = (string) => {
   return string?.charAt(0).toUpperCase() + string.slice(1)
}

export const getZoneClass = (value) => {
   if (!value) return ''
   return ZONE_CLASS.find(z => z.value.toString() === value.toString())?.label || ''
}