import { useCallback, useEffect } from 'react'
import { Route, Redirect, withRouter, RouteProps, Switch, useHistory } from 'react-router-dom'
import 'moment/locale/vi'
import { observer } from 'mobx-react-lite'
import { useStore } from 'src/hooks'
import { LOCAL_STORAGE, ROLES, STATUS_LOGIN } from './helpers/constants'
import { Loading } from 'src/common'
import LoginScreen from 'src/screens/authenticate/LoginScreen'
import ForgotPasswordScreen from 'src/screens/authenticate/ForgotPasswordScreen'
import VerifyEmailScreen from 'src/screens/verify-email/VerifyEmailScreen'
import ResendEmailScreen from 'src/screens/verify-email/ResendEmailScreen'
import LayoutAdmin from 'src/screens/layouts/containers/LayoutAdmin'
import { isEmpty, noop } from 'lodash'
import ResetPasswordScreen from 'src/screens/authenticate/ResetPasswordScreen'
import LayoutNcc from './screens/layouts/containers/LayoutNcc'
import VehicleRegister from './screens/authenticate/VehicleInspectionRegister'
import ConcertRegister from './screens/authenticate/ConcertRegister'
import ConcertRegisterBrand from './screens/authenticate/ConcertRegisterBrand'
import VehicleCounter from './screens/authenticate/VehicleCouter'
interface ProtectedRouteProps extends RouteProps {
   // tslint:disable-next-line:no-any
   component: any
   isSignedIn: number
   role?: string
   currentRole: string
}

const App = observer((props) => {
   const AuthStore: any = useStore('AuthStore')
   const CommonConfigStore = useStore('CommonConfigStore')
   const role: any = AuthStore.data_role
   const history = useHistory()

   useEffect(() => {
      if (process.env.REACT_APP_ENV !== 'DEV') {
         // eslint-disable-next-line no-console
         console.log = noop
         // eslint-disable-next-line no-console
         console.warn = noop
         // eslint-disable-next-line no-console
         console.error = noop
      }
      handle_fetchData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
      // AuthStore.isLogin = STATUS_LOGIN.LOGIN_SUCCESS;
   }, [])

   useEffect(() => {
      if (AuthStore.isLogin === STATUS_LOGIN.LOGIN_SUCCESS) {
         AuthStore.action_getRole(history).then((res: any) => {
            if (!res) {
               // JobsStore.resetJobs()
               return
            }
            // getCommonData()
         })
      }
   }, [AuthStore.isLogin, AuthStore.data_role, AuthStore, history])

   useEffect(() => {
      window.addEventListener('resize', () => {
         AuthStore.width_screen = window.innerWidth
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const handle_fetchData = async () => {
      // if (!CommonConfigStore.linkSupport) {
      //    CommonConfigStore.getListSupport()
      // }
      const dataAuth = await localStorage.getItem(LOCAL_STORAGE.DATA_AUTH)
      const dataUser = await localStorage.getItem(LOCAL_STORAGE.USER_INFOR)

      if (!isEmpty(dataAuth) && dataAuth !== '{}') {
         AuthStore.isLogin = STATUS_LOGIN.LOGIN_SUCCESS
         AuthStore.data_getUserInfo = JSON.parse(dataUser || '{}')
         AuthStore.data_role = JSON.parse(dataUser || '{}')?.role
      } else {
         AuthStore.isLogin = STATUS_LOGIN.NOT_LOGIN
      }
   }

   const isAdminRoute = useCallback(() => {
      if (role === ROLES.ADMIN) return true
      return false
   }, [AuthStore.data_role])

   console.log('====================================')
   console.log(isAdminRoute())
   console.log('====================================')

   return (
      <>
         <Switch>
            <Route
               exact
               path="/signin"
               component={LoginScreen}
               render={() => {
                  return AuthStore.isLogin === STATUS_LOGIN.LOGIN_SUCCESS ? (
                     <Redirect to="/" />
                  ) : (
                     <Redirect to="/signin" />
                  )
               }}
            />

            <Route exact path="/forgot-password" component={ForgotPasswordScreen} />
            <Route exact path="/reset-password" component={ResetPasswordScreen} />
            <Route exact path="/verify-email" component={VerifyEmailScreen} />
            <Route exact path="/resend-verify-email" component={ResendEmailScreen} />
            <Route exact path="/motor-check-register" component={VehicleRegister} />
            <Route exact path="/concert-register" component={ConcertRegister} />
            <Route exact path="/concert-register-brand" component={ConcertRegisterBrand} />
            <Route exact path="/vehicle-counter" component={VehicleCounter} />

            <Switch>
               {isAdminRoute() ? (
                  <ProtectedRoute
                     isSignedIn={AuthStore.isLogin}
                     path="/"
                     component={LayoutAdmin}
                     role={ROLES.ADMIN}
                     currentRole={role}
                  />
               ) : (
                  <ProtectedRoute
                     isSignedIn={AuthStore.isLogin}
                     path="/"
                     component={LayoutNcc}
                     role={ROLES.NCC}
                     currentRole={role}
                  />
               )}
            </Switch>
            <Route path="*" component={VehicleRegister} />
         </Switch>
      </>
   )
})

const ProtectedRoute = (props: ProtectedRouteProps) => {
   const { component: Component, isSignedIn, role, currentRole, ...rest } = props
   const isAuthenticate = () => {
      if (currentRole !== role) return false
      return true
   }
   return (
      <Route
         {...rest}
         render={(routeProps) => {
            if (isSignedIn === STATUS_LOGIN.WAIT_LOGIN) {
               return <Loading isLoading={true} />
            } else if (isSignedIn === STATUS_LOGIN.LOGIN_SUCCESS) {
               if (isAuthenticate()) return <Component {...routeProps} />
               else return <></>
            } else {
               return (
                  <Redirect
                     to={{
                        pathname: '/motor-check-register',
                        state: { from: routeProps.location }
                     }}
                  />
               )
               // @ts-ignore
               // return routeProps.location === '/signin' ? (
               //    <Redirect
               //       to={{
               //          pathname: '/signin',
               //          state: { from: routeProps.location }
               //       }}
               //    />
               // ) : (
               //    <Redirect
               //       to={{
               //          pathname: '/vehicle-inspection-register',
               //          state: { from: routeProps.location }
               //       }}
               //    />
               // )
            }
         }}
      />
   )
}

export default withRouter(App)
