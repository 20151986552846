import { useEffect, useState } from 'react'
import { Modal, Form, Button } from 'antd'
import { observer } from 'mobx-react-lite'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'

import { useStore } from '../../hooks'
import useIsMobile from 'src/hook/hookDevice'

import { QrReader } from 'react-qr-reader';
import { showMessageSuccess } from 'src/helpers/functions'


const QRScanner = observer((props: any) => {
   const { t } = useTranslation()
   const [dataObject, setDataObject] = useState(null);
   const { visible, onOk, onCancel, onCheckin, userSelected } = props
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [isLoading, setIsLoading] = useState(false)
   const CheckinStore = useStore('CheckinStore')
   const [form] = Form.useForm()

   const isMobile = useIsMobile()

   useEffect(() => {
      // getDataEmployee()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const checkinNow = async () => {
      console.log('now');
      if (dataObject) {
         //@ts-ignore
         const update = await CheckinStore.checkinConcept(dataObject?.id as unknown as string)
         if (update) {
            //@ts-ignore
            showMessageSuccess(`Tài khoản ${dataObject?.name} đã checkin thành công!`)
            onCheckin()
            setDataObject(null)
         } else {
            setDataObject(null)
         }
         console.log('update',update, 'dataObject',dataObject);
         
      } else {
         alert('Vé không hợp lệ!')
         setTimeout(() => {
            setDataObject(null)
         }, 1000);
      }
   }

   const resetQR = () => {
      setDataObject(null)
   }

   return (
      <Modal
         width={isMobile ? '95%' : '68%'}
         visible={visible}
         centered
         title={'Quét QR checkin'}
         // okText={t(GLOBAL_CLIENT.txtCreate)}
         cancelText={t(GLOBAL_CLIENT.huy)}
         onCancel={onCancel}
         footer={[
            <>
               <Button key="back" onClick={onCancel}>
                  Đóng
               </Button>
            </>
         ]}
      // onOk={form.submit}
      >
         {
            dataObject ?
               <div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                     <span style={{ fontSize: 14, color: '#000', marginRight: 16 }}>Khách hàng:</span>

                     {/* @ts-ignore */}
                     <span style={{ fontSize: 14, color: '#000', fontWeight: 'bold' }}>{dataObject?.name || ''}</span>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                     <span style={{ fontSize: 14, color: '#000', marginRight: 16 }}>SDT:</span>

                     {/* @ts-ignore */}
                     <span style={{ fontSize: 14, color: '#000', fontWeight: 'bold' }}>{dataObject?.phone || ''}</span>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 16 }}>
                     <span style={{ fontSize: 14, color: '#000', marginRight: 16 }}>Mã vé:</span>
                     {/* @ts-ignore */}
                     <span style={{ fontSize: 14, color: '#000', fontWeight: 'bold' }}>{dataObject?.code || ''}</span>
                  </div>
                  <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                     <Button onClick={checkinNow} type="primary" className="gx-m-0">
                        Checkin
                     </Button>
                     <Button onClick={resetQR} type="ghost" className="gx-m-2" >
                        Quét lại QR
                     </Button>
                  </div>
               </div>
               :
               <QrReader
                  onResult={(result, error) => {
                     if (result) {
                        try {
                           // @ts-ignore
                           const data1 = JSON.parse(result?.text)
                           setDataObject(data1)
                        } catch (error) {
                           console.info(error);
                        }
                     }
                     // if (error) {
                     //    alert("Lỗi quét mã QR:"+ error); 
                     //  }
                     if (!!error) {
                        console.info(error);
                     }
                  }}

                  constraints={{
                     facingMode: "environment", // Sử dụng camera sau
                  }}
                  //@ts-ignore
                  style={{ width: '100%' }}
               />
         }
         {/* <p>{dataObject}</p> */}
      </Modal >
   )
})

export default QRScanner
