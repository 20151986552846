import { useEffect, useState } from 'react'
import { Table, Space, Button, Pagination, Input, Col, Row, Divider, Select } from 'antd'
import { isEmpty, size, trim } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../hooks'
import { PAGE_SIZE, POSITION_INFO, getPage } from '../../helpers/constants'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { Loading } from '../../common'
import Create from './Create'
import Detail from 'src/screens/authenticate/UserDetail'
import useWindowDimensions from '../../common/useWindowDimensions'
import useModal from 'src/helpers/modal/useModal'
import useIsMobile from 'src/hook/hookDevice'
import { CheckinWrapper } from '../authenticate/checkin'
import CardItem from 'src/common/components/CardCheckins/item'
import QRScanner from './QRScanner'
import QRScannerDevice from './QRScannerDevice'

const CheckinMusicManagement = observer(() => {
   const { t } = useTranslation()
   const UserStore = useStore('UserStore')
   const AuthStore = useStore('AuthStore')
   const CheckinStore = useStore('CheckinStore')
   const { data_getUserInfo } = AuthStore
   const [textSearch, set_textSearch] = useState('')
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [isLoading, setIsLoading] = useState(false)
   const { height } = useWindowDimensions()
   const [visibleCreate, setVisibleCreate] = useState(false)
   const [visibleDetail, setVisibleDetail] = useState(false)
   const [visibleScanner, setVisibleScanner] = useState(false)
   const [visibleQRScanner, setVisibleQRScanner] = useState(false)
   const [isTransactionHistoryModalOpen, setIsTransactionHistoryModalOpen] = useState(false)
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [status, setStatus] = useState(POSITION_INFO.ALL.id)
   const [item, setItem] = useState(null)
   const [fieldFilter, setFieldFilter] = useState<any>({})
   const { confirm } = useModal()
   const { rows, offset, count } = UserStore.listEmployees

   const [page, setPage] = useState(0)
   const [userSelected, setUserSelected] = useState<any>(null)

   const isMobile = useIsMobile()

   useEffect(() => {
      if (size(trim(textSearch)) > 0) {
         getListData(0, { ...fieldFilter, ...{ keyword: trim(textSearch) } })
      } else {
         getListData(0, { ...fieldFilter })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [textSearch, fieldFilter])

   const getListData = async (currentPage: number, param?: any) => {
      try {
         // setIsLoading(true)
         const offset = getPage(currentPage)
         const params = { offset, ...param, type: 2 }
         setPage(currentPage)
         // console.log('params', params)

         await UserStore.getEmployees(params)
      } catch (error) {
         // console.log(error)
      } finally {
         setIsLoading(false)
      }
   }

   const onChange = (currentPage: number) => {
      if (size(trim(textSearch)) > 0) {
         getListData(currentPage - 1, { ...fieldFilter, ...{ keyword: trim(textSearch) } })
      } else {
         getListData(currentPage - 1, { ...fieldFilter })
      }
   }

   const handleChangeTextSearch = (value: any) => {
      set_textSearch(value)
   }

   const clearDetailInfo = () => {
      setItem(null)
   }

   const setDetailInfo = (value: any) => {
      setItem(value)
   }

   const showDetail = (value: any) => {
      setDetailInfo(JSON.parse(JSON.stringify(value)))
      // console.log('showDetail; ', JSON.parse(JSON.stringify(value)))

      setTimeout(() => {
         setVisibleDetail(true)
      }, 500)
   }

   const handleEdit = () => {
      if (size(trim(textSearch)) > 0) {
         getListData(0, { ...fieldFilter, ...{ keyword: trim(textSearch) } })
      } else {
         getListData(0, { ...fieldFilter })
      }
      setVisibleDetail(false)
      clearDetailInfo()
   }

   const hideDetail = () => {
      setVisibleDetail(false)
      clearDetailInfo()
   }
   const showCreate = (dataUser: any) => {
      setUserSelected(dataUser)
      setVisibleCreate(true)
   }

   const handleCreate = async () => {
      if (size(trim(textSearch)) > 0) {
         getListData(page, { ...fieldFilter, ...{ keyword: trim(textSearch) } })
      } else {
         getListData(page, { ...fieldFilter })
      }
      setVisibleCreate(false)
   }

   const handleCancel = () => {
      setVisibleCreate(false)
   }

   const handleShowScanner = () => {
      setVisibleScanner(true)
   }
   const handleShowQRScanner = () => {
      setVisibleQRScanner(true)
   }

   const onCheckin = async (id: number) => {
      const update = await CheckinStore.checkinConcept(id as unknown as string)
      setVisibleDetail(false)
      if (update) {
         if (size(trim(textSearch)) > 0) {
            getListData(page, { ...fieldFilter, ...{ keyword: trim(textSearch) } })
         } else {
            getListData(page, { ...fieldFilter })
         }
      }
   }
   const onCheckinRefresh = () => {
      if (size(trim(textSearch)) > 0) {
         getListData(page, { ...fieldFilter, ...{ keyword: trim(textSearch) } })
      } else {
         getListData(page, { ...fieldFilter })
      }
   }

   const columns = [
      {
         title: t(GLOBAL_CLIENT.fullname),
         dataIndex: 'name',
         key: 'name',
         width: 80,
         render: (value: any) => {
            return <span className="txtMax2Line">{value}</span>
         }
         // fixed: 'left',
      },
      {
         title: t(GLOBAL_CLIENT.phone),
         dataIndex: 'phoneNumber',
         width: 100,
         key: 'phoneNumber',
         render: (value: any) => {
            return <span className="txtMax2Line">{value}</span>
         }
      },
      {
         title: t(GLOBAL_CLIENT.txtBKS),
         dataIndex: 'licensePlate',
         width: 100,
         key: 'licensePlate',
         render: (value: any) => {
            return <span className="txtMax2Line">{value}</span>
         }
      },
      {
         title: 'Mã quay thưởng',
         width: 80,
         key: 'chiefCode',
         render: (value: boolean, record: any) => {
            return <span className="txtMax2Line">{record?.chiefCode?.code || ''}</span>
         }
      },
      {
         title: 'Action',
         key: 'action',
         width: 80,
         align: 'center' as 'center',
         fixed: 'right' as 'right',
         render: (record: any) => {
            return (
               <span className="txtMax2Line">
                  <span onClick={() => showDetail(record)} className="gx-link ">
                     {'Chi tiết'}
                  </span>
                  {record?.ticket ? (
                     !!!record?.ticket?.isCheckin ? (
                        <>
                           <Divider type="vertical" />
                           <span onClick={() => onCheckin(record.id)} className="gx-link ">
                              {'Checkin'}
                           </span>
                        </>
                     ) : null
                  ) : (
                     <>
                        <Divider type="vertical" />
                        <span onClick={() => showCreate(record)} className="gx-link ">
                           {'Tạo ticket'}
                        </span>
                     </>
                  )}
               </span>
            )
         }
      }
   ]
   const renderPagination = () => {
      if (isEmpty(rows)) {
         return null
      }

      return (
         <div className="containerPagination">
            <Pagination
               onChange={onChange}
               pageSize={PAGE_SIZE}
               current={offset / PAGE_SIZE + 1}
               showSizeChanger={false}
               total={count}
            />
         </div>
      )
   }



   const handleChangeRole = (value: any) => {
      console.log('value', value)
      if (!value) {
         let x = { ...fieldFilter }
         delete x['filter']
         setFieldFilter(x)
      } else {
         setFieldFilter({ ...fieldFilter, ...{ filter: value } })
      }
   }


   return (
      <>
         <Loading isLoading={isLoading} />
         <Row>
            <Col span={24}>
               <Row className="row-container-head">

                  <Col xs={24} md={8}>
                     <Input.Search onSearch={handleChangeTextSearch} placeholder={t(GLOBAL_CLIENT.search)} allowClear />
                  </Col>
                  <Col xs={12} md={4}>
                     <Select
                        value={fieldFilter.fulfillmentId}
                        placeholder="Tất cả"
                        onChange={handleChangeRole}
                        style={{ minWidth: 100 }}
                     >
                        <Select.Option key={''} value={''}>
                           {'Tất cả'}
                        </Select.Option>
                        <Select.Option key={'Check'} value={'Check'}>
                           {'Kiểm tra xe'}
                        </Select.Option>
                        <Select.Option key={'Concert'} value={'Concert'}>
                           {'Concert'}
                        </Select.Option>

                     </Select>
                  </Col>
                  <Col xs={12} md={12}>
                     <div className="text-right">
                        <Space>
                           <Button type="dashed" className="btn-add" style={{background:'gray',left:-20}} onClick={handleShowQRScanner}>
                              S.N🔫
                           </Button>
                           <Button type="primary" className="btn-add" style={{background:'gray',left:-20}} onClick={handleShowScanner}>
                              Quét mã QR
                           </Button>

                        </Space>
                     </div>
                  </Col>
               </Row>
               <Space
                  className="total-items"
                  style={{ width: '100%', alignItems: 'center', justifyContent: 'space-between' }}
               >
                  <span>
                     {t(GLOBAL_CLIENT.totalItems)}: {count}
                  </span>
               </Space>

               {isMobile ? (
                  <CheckinWrapper style={{}}>
                     {rows.map((row: any) => {
                        return (
                           <CardItem item={row} key={row.id}>
                              <div className="gx-flex-column " style={{ gap: '5px' }}>
                                 <Button onClick={() => showDetail?.(row)} type="primary" className="gx-m-0">
                                    Chi tiết
                                 </Button>
                                 {row?.ticket ? (
                                    !!!row?.ticket?.isCheckin ? (
                                       <Button type="primary" onClick={() => onCheckin(row.id)} className="gx-m-0">
                                          Checkin
                                       </Button>
                                    ) : null
                                 ) : (
                                    <Button type="primary" onClick={() => showCreate(row)} className="gx-m-0">
                                       Tạo ticket
                                    </Button>
                                 )}
                              </div>
                           </CardItem>
                        )
                     })}
                  </CheckinWrapper>
               ) : (
                  <Table
                     scroll={{ x: 1500, y: height - 280 }}
                     columns={columns}
                     dataSource={rows}
                     rowKey="id"
                     pagination={false}
                  />
               )}

               {renderPagination()}
            </Col>
         </Row>
         {visibleCreate && (
            <Create userSelected={userSelected} visible={visibleCreate} onOk={handleCreate} onCancel={handleCancel} />
         )}
         {visibleDetail && item && (
            <Detail visible={visibleDetail} item={item} onOk={handleEdit} onCancel={hideDetail} onCheckin={onCheckin} />
         )}
         {visibleScanner && (
            <QRScanner
               visible={visibleScanner}
               onCancel={() => setVisibleScanner(false)}
               onCheckin={onCheckinRefresh}
            />
         )}
         {visibleQRScanner && (
            <QRScannerDevice
               visible={visibleQRScanner}
               onCancel={() => setVisibleQRScanner(false)}
               onCheckin={onCheckinRefresh}
            />
         )}
      </>
   )
})

export default CheckinMusicManagement
